<template>
  <v-dialog
    :value="isDetailsSidebarActive"
    persistent
    width="600"
    @input="(val) => $emit('update:is-details-sidebar-active', val)"
  >
    <v-card>
      <div class="drawer-header d-flex align-center mb-4">
        <span class="font-weight-semibold text-base text--primary">收款人信息确认</span>
        <v-spacer></v-spacer>
        <v-btn
          icon
          small
          @click="$emit('update:is-details-sidebar-active',false)"
        >
          <v-icon size="22">
            {{ mdiClose }}
          </v-icon>
        </v-btn>
      </div>
      <v-card-text>
        <div>
          请仔细核对开卡人姓名、银行卡号码（仅支持Ⅰ类储蓄卡）、开户行名称、身份证号码及相对应的实名信息与签约合同的收款人信息是否一致，且保证银行卡能正常接收款项。点击“确认”按钮，即视为本人确认该收款信息无误！
        </div>
        <div class="mt-3">
          具体本月收款人信息为：
        </div>
        <v-row
          cols="12"
          class="mt-4 ml-2"
        >
          <v-col cols="4">
            收款人：
          </v-col>
          <v-col cols="8">
            {{ itemDetails.bankAccountName?itemDetails.bankAccountName:'-' }}
          </v-col>
        </v-row>
        <v-row
          cols="12"
          class="ml-2"
        >
          <v-col cols="4">
            开户行：
          </v-col>
          <v-col
            cols="8"
          >
            {{ itemDetails.openingBank?itemDetails.openingBank:'-' }}
          </v-col>
        </v-row>
        <v-row
          cols="12"
          class="ml-2"
        >
          <v-col cols="4">
            支行：
          </v-col>
          <v-col cols="8">
            {{ itemDetails.bankBranchName?itemDetails.bankBranchName:'-' }}
          </v-col>
        </v-row>
        <v-row
          cols="12"
          class="ml-2"
        >
          <v-col cols="4">
            收款账号：
          </v-col>
          <v-col cols="8">
            {{ itemDetails.bankAccount?itemDetails.bankAccount:'-' }}
          </v-col>
        </v-row>
        <v-row
          cols="12"
          class="ml-2"
        >
          <v-col cols="4">
            身份证/社会信用代码：
          </v-col>
          <v-col cols="8">
            {{ itemDetails.idCard?itemDetails.idCard:'-' }}
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="secondery"
          outlined
          @click="resetForm"
        >
          关闭
        </v-btn>
        <v-btn
          color="success"
          @click="examine"
        >
          确认
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import { modalFail, toastSuccess } from '@core/utils/prompt'
import { mdiClose } from '@mdi/js'
import store from '@/store'
import storeModule from './queueStoreModule'

export default {
  model: {
    prop: 'isDetailsSidebarActive',
    event: 'update:is-details-sidebar-active',
  },
  props: {
    isDetailsSidebarActive: {
      type: Boolean,
      required: true,
    },
    id: {
      type: Number,
      required: true,
    },
  },

  setup(props, { emit }) {
    const STORE_MODULE_NAME = 'beSettle-list'

    // Register module
    if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, storeModule)

    // // UnRegister on leave
    // onUnmounted(() => {
    //   if (store.hasModule(POINT_ATTR_STORE_MODULE_NAME)) store.unregisterModule(POINT_ATTR_STORE_MODULE_NAME)
    // })

    const valid = ref(false)
    const form = ref(null)
    const validate = () => {
      form.value.validate()
    }
    const resetForm = () => {
      emit('update:is-details-sidebar-active', false)
    }

    // TODO:可以不需要发ajax请求获取详情（用table中的数据）
    const itemDetails = ref({})
    const fetchItemDetails = () => {
      store.dispatch('beSettle-list/fetchItemDetails', { id: props.id })
        .then(response => {
          const { data } = response.data
          itemDetails.value = data
        })
        .catch(error => {
          modalFail(error)
          if (error.response.status === 404) {
            console.log(error)
          }
        })
    }

    const examine = () => {
      console.log(props.id)
      store.dispatch('beSettle-list/confirm', props.id)
        .then(() => {
          toastSuccess('操作成功')
          emit('refetch-data')
          emit('update:is-details-sidebar-active', false)
        })
        .catch(error => {
          modalFail(error.response.data.message)
        })
    }

    watch(
      () => props.isDetailsSidebarActive,
      () => {
        if (props.isDetailsSidebarActive === true) {
          fetchItemDetails()
        }
      },
    )

    return {
      itemDetails,
      form,
      valid,
      validate,
      resetForm,
      examine,
      mdiClose,
    }
  },
}
</script>

<style scoped>
.busLicense:hover{
  cursor:zoom-in;
}
</style>
