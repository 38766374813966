<template>
  <v-dialog
    :value="isDialogVisible"
    scrollable
    max-width="400px"
    @input="(val) => $emit('update:is-dialog-visible', val)"
  >
    <v-card>
      <v-card-title>以下账单收款人未确认，请先确认！</v-card-title>
      <v-divider></v-divider>
      <v-card-text style="height: 300px;white-space: pre-line;">
        <span
          v-for="item in unConfirmArr"
          :key="item.id"
        >{{ item.channelName }}({{ item.month }}月)<br></span>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-btn
          color="blue"
          text
          @click="$emit('update:is-dialog-visible', false)"
        >
          好的
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  model: {
    prop: 'isDialogVisible',
    event: 'update:is-dialog-visible',
  },
  props: {
    isDialogVisible: {
      type: Boolean,
      required: true,
    },
    unConfirmArr: {
      type: Array,
      required: true,
    },
  },
  setup(props, { emit }) {
    const resetForm = () => {
      emit('update:is-dialog-visible', false)
    }

    return {
      resetForm,
    }
  },
}
</script>

<style scoped>

</style>
