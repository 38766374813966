<template>
  <div>
    <un-confirm
      :un-confirm-arr="unConfirmArr"
      :is-dialog-visible.sync="isDialogVisible"
    ></un-confirm>
    <payee
      :id="itemId"
      :is-details-sidebar-active.sync="isDetailsSidebarActive"
      @refetch-data="fetchQueueList"
    ></payee>
    <div class="d-flex">
      <h2
        class="titleLeft"
        :style="`${$vuetify.theme.isDark === true?'color: #FFFFFF':'color: #8E8997'}`"
      >
        待结算
      </h2>
      <v-spacer></v-spacer>
      <v-btn
        color="info"
        class="mt-3"
        @click="confirmAll"
      >
        一键确认账单
      </v-btn>
    </div>
    <div v-if="queueList.length === 0">
      暂无数据！
    </div>
    <v-row
      v-if="queueList.length !== 0"
      class="d-flex justify-start"
    >
      <v-col
        v-for="item in queueList"
        :key="item.id"
        md="6"
      >
        <v-card
          class="monthCard"
          :style="`${$vuetify.theme.isDark === true?'background-color: #312D4B':'background-color: #FFFFFF'}`"
        >
          <v-card-title>
            <div style="width: 6px;height: 30px;background: #8F54FC;margin-right: 11px"></div>
            <div>{{ item.channelName }}</div>
            <v-spacer></v-spacer>
            <span
              class="primary--text"
              style="font-size: 16px"
            >
              {{ item.days?JSON.parse(item.days).start.slice(0,11):'-' }}
              ~
              {{ item.days?JSON.parse(item.days).end.slice(0,11):'-' }}
            </span>
            <v-spacer></v-spacer>
          </v-card-title>
          <div class="rightSign">
            <div class="signText">
              {{ item.month }}月账单
            </div>
          </div>
          <v-container class="ml-7 pr-3">
            <v-row
              class="containerText"
              :style="`${$vuetify.theme.isDark === true?'color: #E7E3FC':'color: #79767E'}`"
            >
              <v-col
                md="4"
                class="d-flex flex-column"
              >
                <span class="mb-8">播放次数：{{ item.views }}</span>
                <span class="mb-8">汇率：{{ item.rate }}</span>
                <span class="mb-8">CP分成：{{ item.splitRatio }}%</span>
              </v-col>
              <v-col class="d-flex flex-column">
                <span class="mb-8">估算收入：${{ item.estimatedRevenue }}</span>
                <span class="mb-8">收益：{{ item.incomeTotal }}</span>
                <span class="mb-8">手续费：{{ item.taxDollar }}</span>
              </v-col>
            </v-row>
            <span class="realText">实发：￥{{ item.incomeRmb }}</span>
            <v-row class="mt-9">
              <v-col md="5">
                <div class="platform">
                  <v-img
                    v-if="item.type==='YT'"
                    src="../../../assets/images/platform/yt.png"
                  ></v-img>
                  <v-img
                    v-if="item.type==='FB'"
                    src="../../../assets/images/platform/fb.png"
                  ></v-img>
                </div>
              </v-col>
              <v-col md="2" class="ml-n9 mr-lg-12">
                <v-btn
                  v-show="item.showPayeeFlag===1"
                  color="info"
                  class="bottomBtn pa-0"
                  x-large
                  @click="confirm(item.id)"
                >
                  收款人
                </v-btn>
              </v-col>
              <v-col md="3">
                <v-btn
                  color="primary"
                  class="bottomBtn"
                  x-large
                  :to="{name:'billSettlement-report',query:{id:item.id} }"
                >
                  查看PDF
                </v-btn>
              </v-col>
              <v-col
                md="1"
                class="ml-n2 mt-3"
              >
                <span class="text-no-wrap">
                  {{ item.cpCheck===1?'已确认':'未确认' }}
                </span>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { ref, onUnmounted } from '@vue/composition-api'
import { modalFail, toastSuccess } from '@core/utils/prompt'
import store from '@/store'
import storeModule from './queueStoreModule'
import beSettleQueueList from './beSettleQueueList'
import Payee from './Payee.vue'
import UnConfirm from './unConfirm.vue'

export default {
  components: {
    UnConfirm,
    Payee,
  },
  inheritAttrs: false,
  props: {
  },
  setup() {
    const WORK_STORE_MODULE_NAME = 'beSettle-list'

    // Register module
    if (!store.hasModule(WORK_STORE_MODULE_NAME)) store.registerModule(WORK_STORE_MODULE_NAME, storeModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(WORK_STORE_MODULE_NAME)) store.unregisterModule(WORK_STORE_MODULE_NAME)
    })
    const isDetailsSidebarActive = ref(false)
    const isDialogVisible = ref(false)
    const itemId = ref(0)
    const isAdd = ref(false)
    const unConfirmArr = ref([])

    const {
      queueList,
      fetchQueueList,
      headers,
      options,
      totalLists,
      createdUser,
      channelName,
      platformFilter,
      statusFilter,
      loading,
      page,
      pageSize,
      pageLength,
    } = beSettleQueueList()

    const confirm = id => {
      itemId.value = id
      isDetailsSidebarActive.value = !isDetailsSidebarActive.value
    }

    const confirmAll = () => {
      if (queueList.value.length === 0) {
        modalFail('没有账单需要确认！')
      } else {
        let isConfirmPayee = true
        const arr = []
        queueList.value.forEach(item => {
          if (item.showPayeeFlag === 1) {
            isConfirmPayee = false
            arr.push(item)
          }
        })
        if (isConfirmPayee === false) {
          unConfirmArr.value = arr
          isDialogVisible.value = !isDialogVisible.value

          // modalDisclaimers('请先确认以下账单收款人信息!', '32433<br>3423453')
        } else {
          store.dispatch('beSettle-list/confirmAll')
            .then(response => {
              console.log(response.data.data)
              if (response.data.status === 200) {
                toastSuccess('操作成功！')
                fetchQueueList()
              }
            })
            .catch(error => {
              console.log(error.response)
              modalFail(error.response.data.message)
            })
        }
      }
    }

    const search = () => {
      loading.value = true
      options.value.page = 1
      fetchQueueList()
    }

    // 导入通过相关字符串获取数据的函数
    const open = ref([1, 2])
    const isRemoved = ref(false)

    return {
      queueList,
      totalLists,
      page,
      pageSize,
      pageLength,
      fetchQueueList,
      headers,
      loading,
      open,
      isRemoved,
      itemId,
      isAdd,
      isDialogVisible,
      isDetailsSidebarActive,
      unConfirmArr,
      options,
      createdUser,
      channelName,
      platformFilter,
      statusFilter,
      search,
      confirm,
      confirmAll,
    }
  },
}
</script>

<style scoped>
.monthCard{
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 20px;
  width: 736px;
  min-height: 420px;
  border-radius: 6px;
  overflow: hidden;
  position: relative;
}
.rightSign{
  position: absolute;
  right: -63px;
  top: 29px;
  width: 264px;
  height: 63px;
  background: linear-gradient(-30deg, #7336FF, #F076FF);
  box-shadow: -3px 2px 8px 0px rgba(49,0,138,0.1);
  border-radius: 6px;
  -moz-transform:rotate(45deg);
  -webkit-transform:rotate(45deg);
  -o-transform:rotate(45deg);
  transform:rotate(45deg);
  filter:progid:DXImageTransform.Microsoft.BasicImage(rotation=1);
}
.signText{
  width: 138px;
  margin-top: 16px;
  margin-left: 71px;
  font-size: 18px;
  font-family: Microsoft YaHei,sans-serif;
  font-weight: 400;
  color: #FFFFFF;
}
.containerText{
  font-size: 16px;
  font-family: Microsoft YaHei,sans-serif;
  font-weight: 400;
}
.titleLeft{
  font-size: 20px;
  font-family: Microsoft YaHei,sans-serif;
  font-weight: bold;
  line-height: 35px;
  margin-bottom: 25px;
}
.realText{
  width: 139px;
  height: 20px;
  font-size: 20px;
  font-family: Microsoft YaHei,sans-serif;
  font-weight: bold;
  color: #8F54FC;
}
.platform{
  width: 165px;
  height: 32px;
}
.bottomBtn{
  width: 140px;
  border-radius: 4px;
}
</style>
